import { SHARE, DEMO, SIGNUP } from '@/config/path';
import { matchPath } from 'react-router-dom';

function useRouteChecker({ pathname }: { pathname: string }) {
	const isAuthPage = matchPath('/landing/*', pathname);
	const isAppPage = matchPath('/app/*', pathname);
	const isPublicPage =
		matchPath(`/${SHARE.INDEX}/*`, pathname) ||
		matchPath(`/${DEMO.INDEX}`, pathname) ||
		matchPath(`/${SIGNUP}`, pathname);

	return {
		isAuthPage,
		isAppPage,
		isPublicPage,
	};
}
export default useRouteChecker;
